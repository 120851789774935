import React, {useEffect} from 'react';
// import {
//     Box,
//     Container, Fab,
//     FormControl, FormControlLabel,
//     FormLabel,
//     Grid,
//     InputLabel,
//     MenuItem, Radio,
//     RadioGroup,
//     Select, TextField, Typography, Icon, Button, Badge, Drawer
// } from "@material-ui/core";

import {
    Box,
    Container,
    FormControl, FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem, Radio,
    RadioGroup,
    Select, TextField, Typography
} from "@material-ui/core";

import './global.css'
import data from './data.json'

function App() {

    const [customerPhoto, setCustomerPhoto] = React.useState('color');
    const [colorize, setColorize] = React.useState('no');
    const [quality, setQuality] = React.useState('good');
    const [complexity, setComplexity] = React.useState('good');
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [complexityPrice, setComplexityPrice] = React.useState(0);
    const [numberOfPeople, setNumberOfPeople] = React.useState(1);

    useEffect(() => {
        const d = data.find(item => item.quality === quality && item.complexity === complexity);
        if (d !== undefined) {
            setComplexityPrice(d.price)
        }
    }, [quality, complexity])

    useEffect(() => {
        const c = colorize === 'yes' && customerPhoto === 'black_and_white' ? 100 : 0;
        setTotalPrice((numberOfPeople * 50) + complexityPrice + c)
    }, [complexityPrice, numberOfPeople, colorize, customerPhoto])


    const customerPhotoHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCustomerPhoto(event.target.value as string);
    };

    const colorizeHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setColorize(event.target.value as string);
    };

    const qualityHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setQuality(event.target.value as string);
    };

    const сomplexityHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setComplexity(event.target.value as string);
    };

    const numberOfPeopleHandlerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNumberOfPeople(event.target.value as number);
    };








    // const [state, setState] = React.useState(false);
    //
    // const toggleDrawer = (open: boolean) => (
    //     event: React.KeyboardEvent | React.MouseEvent,
    // ) => {
    //     if (
    //         event.type === 'keydown' &&
    //         ((event as React.KeyboardEvent).key === 'Tab' ||
    //             (event as React.KeyboardEvent).key === 'Shift')
    //     ) {
    //         return;
    //     }
    //
    //     setState(open);
    // };

    return (
    <div className="app">

        <Container maxWidth="lg">
            <Grid
                container
                justifyContent="space-between"
                spacing={4}
            >
                <Grid container item sm={4}>
                    <Box width={'100%'} marginBottom={4}>
                        <Typography variant="h3" component="h3">
                            Calculator prețuri
                        </Typography>
                    </Box>

                    <FormControl component="fieldset" fullWidth={true} margin={'dense'}>
                        <FormLabel component="legend">Imaginea clientului este</FormLabel>
                        <RadioGroup aria-label="customerPhoto" name="customerPhoto" value={customerPhoto} onChange={customerPhotoHandleChange}>
                            <FormControlLabel value="color" control={<Radio />} label="Coloră" />
                            <FormControlLabel value="black_and_white" control={<Radio />} label="Alb & Negru" />
                        </RadioGroup>
                    </FormControl>

                    {customerPhoto === 'black_and_white' &&
                        <FormControl component="fieldset" fullWidth={true} margin={'dense'}>
                            <FormLabel component="legend">Poza trebuie de o colorat?</FormLabel>
                            <RadioGroup aria-label="colorize" name="colorize" value={colorize} onChange={colorizeHandleChange}>
                                <FormControlLabel value={'yes'} control={<Radio />} label="Da" />
                                <FormControlLabel value={'no'} control={<Radio />} label="Nu" />
                            </RadioGroup>
                        </FormControl>
                    }

                    <FormControl fullWidth={true} margin={'dense'}>
                        <InputLabel id="quality">Calitatea</InputLabel>
                        <Select
                            labelId="quality"
                            id="quality"
                            value={quality}
                            onChange={qualityHandleChange}
                        >
                            <MenuItem value='very_bad'>Foarte rău</MenuItem>
                            <MenuItem value='bad'>Rău</MenuItem>
                            <MenuItem value='medium'>Mediu</MenuItem>
                            <MenuItem value='good'>Bine</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth={true} margin={'dense'}>
                        <InputLabel id="сomplexity">Complexity</InputLabel>
                        <Select
                            labelId="сomplexity"
                            id="сomplexity"
                            value={complexity}
                            onChange={сomplexityHandleChange}
                        >
                            <MenuItem value='very_bad'>Foarte rău</MenuItem>
                            <MenuItem value='bad'>Rău</MenuItem>
                            <MenuItem value='medium'>Mediu</MenuItem>
                            <MenuItem value='good'>Bine</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth={true} margin={'dense'}>
                        <TextField
                            id="standard-number"
                            label="Numărul de persoane pe poză"
                            type="number"
                            onChange={numberOfPeopleHandlerChange}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            value={numberOfPeople}
                        />
                    </FormControl>

                    <Box marginTop={5} width={'100%'} boxShadow={3} borderRadius={5} p={2} style={{background: '#ffffff'}}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={4}
                        >

                            <Grid container item>
                                <Typography variant="h4" component="h4" align={'center'} display={'block'}  style={{width: '100%'}}>
                                    <em style={{color: '#aaaaaa'}}>Preț:</em> {totalPrice} lei
                                </Typography>
                            </Grid>
                            {/*<Grid container item>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        color="primary"*/}
                            {/*        size="large"*/}
                            {/*        startIcon={<Icon>add</Icon>}*/}
                            {/*        fullWidth={true}*/}
                            {/*    >*/}
                            {/*        Adaugă la comandă*/}
                            {/*    </Button>*/}
                            {/*    <Button>*/}
                            {/*        <Icon>add</Icon>*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>



                </Grid>








                <Grid container item sm={8} alignItems={'flex-start'}>
                    <Box borderRadius={20} boxShadow={3} overflow={'hidden'}>

                        <img src={`/images-old-new-photos/complexity/quality_${quality}/${customerPhoto}/${complexity}.jpg`} width={'100%'} alt="Example"/>
                    </Box>
                </Grid>
            </Grid>





        </Container>

        {/*<React.Fragment>*/}
        {/*    <Fab className='fab' color="primary" size={'large'} onClick={toggleDrawer(true)}>*/}
        {/*        <Badge color="secondary" overlap="circular" badgeContent="3">*/}
        {/*            <Icon>description</Icon>*/}
        {/*        </Badge>*/}
        {/*    </Fab>*/}
        {/*    <Drawer anchor='right' open={state} onClose={toggleDrawer(false)}>*/}
        {/*        <Box maxWidth={500} m={5}>*/}
        {/*            <TextField*/}
        {/*                id="outlined-helperText"*/}
        {/*                label="Helper text"*/}
        {/*                defaultValue="Default Value"*/}
        {/*                helperText="Some important text"*/}
        {/*                variant="outlined"*/}
        {/*            />*/}
        {/*        </Box>*/}
        {/*    </Drawer>*/}
        {/*</React.Fragment>*/}

    </div>
  );
}

export default App;
